import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PoiDisplayService } from '../../services/poi-display.service';

@Component({
  selector: 'app-loading-animation',
  templateUrl: './loading-animation.component.html',
  styleUrls: ['./loading-animation.component.scss']
})
export class LoadingAnimationComponent implements OnInit, OnDestroy {
  readonly loaderWidth = 218;
  readonly pxPerPercent = this.loaderWidth/100;

  loaderAvailable = false;
  currentDisplayLoadingState = 0;

  private currentPercentLoaded = 0;

  private poisTotalLength: number;
  private poisCurrentLength: number = 0;

  private poisSubscription: Subscription;
  private countersSubscription: Subscription;

  constructor(private poiDisplayService: PoiDisplayService) { }

  async ngOnInit() {
    // Wait for image mask to be loaded, so there is not just a grey square visible initially
    let imgUrl = "";
    const mq = window.matchMedia("only screen and (min-device-pixel-ratio: 2)");
    if (mq.matches) {
      imgUrl = "/assets/images/icons/ethorama_logo_mask_2x.png";
    } else {
      imgUrl = "/assets/images/icons/ethorama_logo_mask.png";
    }
    let img = document.createElement("img");
    img.src = imgUrl;
    img.onload = () => {
      this.loaderAvailable = true;
      img.remove();
    };

    this.countersSubscription = this.poiDisplayService.counters.subscribe(counters => {
      if (counters) {
        this.poisTotalLength = counters.pois;
      }
    });

    this.poisSubscription = this.poiDisplayService.pois.subscribe(pois => {
      this.poisCurrentLength += pois.length;
      this.calculateCurrentLoadingState();
    });

    this.updateLoadingStateDisplay();
  }

  ngOnDestroy(): void {
    if (this.poisSubscription) {
      this.poisSubscription.unsubscribe();
    }
    if (this.countersSubscription) {
      this.countersSubscription.unsubscribe();
    }
  }

  timeoutLoadingStateDisplay() {
    setTimeout(() => {
      this.updateLoadingStateDisplay();
    },50)
  }

  updateLoadingStateDisplay() {
    if (this.currentPercentLoaded - this.currentDisplayLoadingState > 25) {
      this.currentDisplayLoadingState = Math.floor(this.currentPercentLoaded);
    }
    if (this.currentDisplayLoadingState < 30 || this.currentPercentLoaded > this.currentDisplayLoadingState - 20) {
      this.currentDisplayLoadingState = this.currentDisplayLoadingState + 5;
    }
    if (this.currentDisplayLoadingState < 100) {
      this.timeoutLoadingStateDisplay();
    } else {
      this.currentDisplayLoadingState = 100;
    }
  }

  calculateCurrentLoadingState() {
    if (!this.poisTotalLength) {
      return;
    }
    this.currentPercentLoaded = (this.poisCurrentLength * 100)/this.poisTotalLength;
  }
}

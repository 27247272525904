import { Injectable, Predicate } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, lastValueFrom, map, reduce, ReplaySubject } from 'rxjs';
import { CountersDto } from '../dtos/counters.dto';
import { DossierDto } from '../dtos/dossier/dossier.dto';
import { LocalizedStringDto } from '../dtos/localized-string.dto';

import { PaginationDto } from '../dtos/pagination.dto';
import { PoiContentSubsetDto } from '../dtos/poi/poi-content-subset.dto';
import { PoiDetailsDto } from '../dtos/poi/poi-details.dto';
import { PoiOverviewDto } from '../dtos/poi/poi-overview.dto';
import { RouteDto } from '../dtos/route/route.dto';
import { CollectionType } from '../enums/collection-type.enum';
import { SortType } from '../enums/sort-type.enum';
import { PoiService } from './poi.service';

@Injectable({
  providedIn: 'root'
})
export class PoiDisplayService {
  loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  pois: ReplaySubject<PoiOverviewDto[]> = new ReplaySubject<PoiOverviewDto[]>();

  filteredPois: BehaviorSubject<PoiOverviewDto[]> = new BehaviorSubject<PoiOverviewDto[]>([]);

  selectedPoi: BehaviorSubject<PoiDetailsDto | null> = new BehaviorSubject<PoiDetailsDto | null>(null);

  collectionPois: BehaviorSubject<PoiOverviewDto[] | null> = new BehaviorSubject<PoiOverviewDto[] | null>(null);

  selectedCollection: DossierDto | RouteDto | null = null;

  updatingAfterBroswerNavigation = false;

  shouldRecenterMap: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  recenteringInProgress: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  shouldCloseDrawer: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  counters = new BehaviorSubject<CountersDto | null>(null);

  mobileQuery: MediaQueryList;
  mobileQueryListener: () => void;

  storedCenter: google.maps.LatLngLiteral | null;
  storedZoom: number | null;
  
  private poisPerRequest = 1100;

  private pagination: PaginationDto<PoiOverviewDto>;

  private boundsFilter: Predicate<PoiOverviewDto> = this.defaultFilter;

  constructor(
    private poiService: PoiService,
    private translateService: TranslateService
  ) {}

  setCounters(counters: CountersDto) {
    this.counters.next(counters);
  }

  setBoundsFilter(filter: Predicate<PoiOverviewDto>) {
    this.boundsFilter = filter;
    this.applyFilter();
  }
  
  clearPoi() {
    this.selectedPoi.next(null);
  }

  clearCollection() {
    this.selectedCollection = null;
    this.collectionPois.next(null);
  }

  async loadPois() {
    if (this.loaded.value === false && this.loading.value === false) {
      this.loading.next(true);
      this.loadPage(0);
    }
  }

  async selectPoi(poi: PoiOverviewDto) {
    return this.selectPoiById(poi.id);
  }

  async selectPoiById(id: string) {
    const selected = await this.poiService.get(id);
    if (this.selectedCollection) {
      let filteredPoiDetails = selected;
      let poiFromCollection: PoiContentSubsetDto | undefined;
      if (this.selectedCollection instanceof DossierDto) {
        poiFromCollection = this.selectedCollection.dossierEntries.find(poiSubset => poiSubset.poi.id === selected.id);
      }
      if (this.selectedCollection instanceof RouteDto) {
        poiFromCollection = this.selectedCollection.routeEntries.find(poiSubset => poiSubset.poi.id === selected.id);
      }
      let itemIdsFromCollection = poiFromCollection?.contentItems.flatMap(item => item.id)
      filteredPoiDetails.contentItems = filteredPoiDetails.contentItems.filter(item => itemIdsFromCollection?.includes(item.id));

      this.selectedPoi.next(filteredPoiDetails);
    } else {
      this.selectedPoi.next(selected);
    }
  }

  async selectPoiDetails(poiDetails: PoiDetailsDto) {
    if (this.selectedCollection) {
      let filteredPoiDetails = poiDetails;
      let poiFromCollection: PoiContentSubsetDto | undefined;
      if (this.selectedCollection instanceof DossierDto) {
        poiFromCollection = this.selectedCollection.dossierEntries.find(poiSubset => poiSubset.poi.id === poiDetails.id);
      }
      if (this.selectedCollection instanceof RouteDto) {
        poiFromCollection = this.selectedCollection.routeEntries.find(poiSubset => poiSubset.poi.id === poiDetails.id);
      }
      let itemIdsFromCollection = poiFromCollection?.contentItems.flatMap(item => item.id)
      filteredPoiDetails.contentItems = filteredPoiDetails.contentItems.filter(item => itemIdsFromCollection?.includes(item.id));

      this.selectedPoi.next(filteredPoiDetails);
    } else {
      this.selectedPoi.next(poiDetails);
    }
  }

  async selectCollection(collection: DossierDto | RouteDto) {
    let isRoute = false;
    this.selectedCollection = collection;
    let collectionPoiIds: string[] = [];
    if (collection instanceof DossierDto) {
      collectionPoiIds = collection.dossierEntries.flatMap(entry => entry.poi.id);
    }
    if (collection instanceof RouteDto) {
      collectionPoiIds = collection.routeEntries.flatMap(entry => entry.poi.id);
      isRoute = true;
    }
    let allPois = await lastValueFrom(
      this.pois.pipe(
        reduce((accumulator, value) => accumulator.concat(value))
      )
    );
    let collectionPois = allPois.filter(poi => collectionPoiIds?.indexOf(poi.id) >= 0 ?? false);
    if (isRoute) {
      collectionPois = this.sortRoute(collectionPois, collectionPoiIds);
    } else {
      collectionPois = this.sortDossier(collectionPois);
    }
    this.collectionPois.next(collectionPois);
  }

  private sortRoute(collectionPois: PoiOverviewDto[], sortedIdList: string[]): PoiOverviewDto[] {
    const sortOrder: { [key: string]: number} = {};
    for (let index = 0; index < sortedIdList.length; index++) {
      sortOrder[sortedIdList[index]] = index;
    }
    return collectionPois.sort((a, b) => {
      return sortOrder[a.id] - sortOrder[b.id];
    });
  }

  private sortDossier(collectionPois: PoiOverviewDto[]): PoiOverviewDto[] {
    let lang = this.translateService.currentLang as keyof LocalizedStringDto;

    return collectionPois.sort((a, b) => {
      let nameA = a.name[lang] ?? a.name.de;
      let nameB = b.name[lang] ?? b.name.de;
      if (nameA && !nameB) {
        return 1;
      }
      if (!nameA && nameB) {
        return -1;
      }
      if (nameA && nameB) {
        return nameA > nameB ? 1 : -1;
      }
      return 0;
    });
  }

  private async applyFilter() {
    let lang = this.translateService.currentLang;
    let allPois = await lastValueFrom(
      this.pois.pipe(
        reduce((accumulator, value) => accumulator.concat(value)),
      ).pipe(
        map(val => val.sort((a: PoiOverviewDto, b: PoiOverviewDto) => {
          let nameA = a.name[lang as keyof LocalizedStringDto] ?? a.name['de'] ?? '';
          let nameB = b.name[lang as keyof LocalizedStringDto] ?? b.name['de'] ?? '';
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        })
      )
    ));
    this.filteredPois.next(allPois.filter(this.boundsFilter));
  }

  private async loadPage(pageNumber: number) {
    this.pagination = await this.poiService.getPaginated(pageNumber, this.poisPerRequest, SortType.Name, true, { overview: true });
    this.pois.next(this.pagination.items);

    // TODO: Make use of pagination.next? (Needs API changes.)
    if (this.pagination.pageNr < this.pagination.totalPages - 1) {
      this.loadPage(++this.pagination.pageNr);
    } else {
      this.pois.complete();
      this.loading.next(false);
      this.loaded.next(true);
    }
  }

  get defaultFilter() {
      return ((poi: PoiOverviewDto) => true);
  }

  get collectionType() : CollectionType {
    if (this.selectedCollection) {
      if (this.selectedCollection instanceof RouteDto) {
        return CollectionType.Route;
      }
      if (this.selectedCollection instanceof DossierDto) {
        return CollectionType.Dossier;
      }
    }
    return CollectionType.None;
  }

  get mobile() : boolean {
    return this.mobileQuery && this.mobileQuery.matches;
  }
}

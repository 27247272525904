import { IsArray, IsBoolean, IsNotEmpty, IsNumber, IsOptional, IsString, ValidateNested } from 'class-validator';
import { LocalizedStringDto } from '../localized-string.dto';
import { PoiContentSubsetDto } from '../poi/poi-content-subset.dto';
import { Type } from 'class-transformer';
import { TrackedUpdate } from '../tracked-update.dto';

export class RouteUpdateDto extends TrackedUpdate {

  @IsOptional() @IsNumber()
  objectId: number | null;

  @IsOptional() @IsString()
  nodeId: string | null;

  @IsBoolean()
  online: boolean = false;

  @IsNotEmpty() @ValidateNested()
  title: LocalizedStringDto;

  @IsNotEmpty() @ValidateNested()
  description: LocalizedStringDto;

  @IsNotEmpty() @ValidateNested()
  description2: LocalizedStringDto;

  @IsBoolean()
  migrated: boolean = false;

  @IsOptional()
  year: string | null;

  @IsOptional() @IsNotEmpty()
  thumbnail: string | null;

  @IsArray() @ValidateNested({ each: true }) @Type(() => PoiContentSubsetDto)
  routeEntries: PoiContentSubsetDto[];

}

import { LocalizedStringDto } from '../localized-string.dto';
import {
  IsArray, IsBoolean, IsNotEmpty, IsNumber, IsOptional, IsString, ValidateNested
} from 'class-validator';
import { PoiContentSubsetDto } from '../poi/poi-content-subset.dto';
import { Type } from 'class-transformer';
import { TrackedUpdate } from '../tracked-update.dto';

export class DossierUpdateDto extends TrackedUpdate {

  @IsNotEmpty() @ValidateNested()
  title: LocalizedStringDto;

  @IsNotEmpty() @ValidateNested()
  description: LocalizedStringDto;

  @IsOptional() @IsString()
  nodeId: string | null;

  @IsOptional() @IsNumber()
  objectId: number | null;

  @IsBoolean()
  online: boolean = false;

  @IsBoolean()
  migrated: boolean = false;

  @IsOptional() @IsString()
  thumbnail: string | null;

  @IsArray() @ValidateNested({ each: true }) @Type(() => PoiContentSubsetDto)
  dossierEntries: PoiContentSubsetDto[];

}

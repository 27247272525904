import { Environment } from './environment.interface';

export const environment: Environment = {
  production: false,
  name: 'dev',
  logLevel: 'info',
  apiUrl: 'https://api.library.ethz.ch/ethorama-dev/v1',
  apiKey: 'whoInQALsTnNZYSOGC9CiJnO4heFGAdtCfALJ768ks5P3Gbx',
  googleMapsKey: 'AIzaSyBuLNar09QFW349kOZwResJEaaJBHM4RuY'
};

import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { PoiDetailsDto } from '../dtos/poi/poi-details.dto';
import { Injectable } from '@angular/core';
import { PoiDto } from '../dtos/poi/poi.dto';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class PoiService extends ApiService<PoiDto, PoiDetailsDto> {
  constructor(protected override http: HttpClient, protected override translateService: TranslateService) {
    super(http, PoiDetailsDto, 'pois', translateService);
  }
}

export class PoiDisplayType {
    static readonly Info = new PoiDisplayType('info', 'PLACE', 'info');
    static readonly Image = new PoiDisplayType('image', 'IMAGES', 'image'); // TODO: Use photo_camera as icon instead?
    static readonly Document = new PoiDisplayType('document', 'DOCUMENTS', 'description');
    static readonly Map = new PoiDisplayType('map', 'MAPS', 'map');
  
    private static all: PoiDisplayType[];
  
    readonly key: string;
    readonly label: string;
    readonly icon: string;
  
    protected constructor(key: string, label: string, icon: string) {
      this.key = key;
      this.label = label;
      this.icon = icon;
      PoiDisplayType.getAll().push(this);
    }
  
    static getAll(): PoiDisplayType[] {
      if (!PoiDisplayType.all) {
        PoiDisplayType.all = [];
      }
      return PoiDisplayType.all;
    }
  
    static getByKey(key: string): PoiDisplayType | null {
      return this.getAll().find(offer => offer.key === key) ?? null;
    }

    static getContentTypes(): PoiDisplayType[] {
      return [PoiDisplayType.Image, PoiDisplayType.Document, PoiDisplayType.Map];
    }
  
    toString(): string {
      return this.key;
    }
  }
  
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { firstValueFrom } from 'rxjs';

import { environment } from '../../../environments/environment';
import { CountersDto } from '../dtos/counters.dto';

@Injectable({
  providedIn: 'root'
})
export class CountersService {
  constructor(protected http: HttpClient) {}

  async get(): Promise<CountersDto> {
    const request = this.http.get(
      `${environment.apiUrl}/util/counters`,
      environment.apiKey ? { params: { apikey: environment.apiKey } } : undefined
    );
    const dto = await firstValueFrom(request);
    return plainToClass(CountersDto, dto);
  }
}

import { ReferenceDto } from '../reference.dto';
import { IsArray, IsNotEmpty, ValidateNested } from 'class-validator';
import { Type } from 'class-transformer';


export class PoiContentSubsetDto {

  @IsNotEmpty() @ValidateNested()
  poi: ReferenceDto;

  @IsArray() @ValidateNested({ each: true }) @Type(() => ReferenceDto)
  contentItems: ReferenceDto[];

}

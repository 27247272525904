import { Component, ElementRef, ViewChild } from '@angular/core';

// Component to draw focus in the material-sidenav, so there is no focus of the search field
@Component({
  selector: 'app-init-focus',
  template: '<button cdkFocusInitial tabindex="-1" (focus)="blur(button)" #focus></button>',
  styleUrls: ['./init-focus.component.scss']
})
export class InitFocusComponent {
  @ViewChild("focus") button: ElementRef;

  blur(e: ElementRef) {
    e.nativeElement.blur();
  }

  constructor() { }
}

<div class="list-item icon-links" mat-list-item>
  <ng-container *ngFor="let type of tabTypes">
    <div *ngIf="getPoiHasType(type)" class="icon-link" [class.active]="display === type" (click)="setDisplay(type)">
      <mat-icon>{{ type.icon }}</mat-icon>
      <span class="content-item-number">{{ getItemsCount(type) }}</span>
    </div>
  </ng-container>
</div>

<div class="list-item subtitle">
  <div>{{ 'CONTENT_ITEMS.' + display.label | translate }}</div>
  <div class="share-links">
    <a [href]="'http://www.facebook.com/share.php?u=' + currentLink" class="share-facebook" target="_blank" rel="noopener"></a>
    <a [href]="'http://twitter.com/share?url=' + currentLink" class="share-twitter" target="_blank" rel="noopener"></a>
  </div>
</div>
<div class="list-item title">
  <h1 class="h1">{{ poi.name | localizeString }}</h1>
</div>
<ng-container *ngTemplateOutlet="display.key === 'info' ? info : content;context:{items:getItems(display)}"></ng-container>

<ng-template #info>
  <div class="list-item description-item" *ngIf="poi.description">
    <p>{{ poi.description | localizeString }}</p>
  </div>
  <div class="list-item" *ngFor="let ref of poi.references | keyvalue">
    <a class="reference-link" *ngIf="ref.key === 'discovery' || currentLanguageValueExists(ref.value)" [href]="ref.value | localizeString" target="_blank" rel="noopener">
      {{ 'EXTERNAL_LINKS.' + linkText[ref.key] | translate }}
    </a>
  </div>
</ng-template>

<ng-template #content let-items="items">
  <div class="list-item subtitle">
    <span>{{ 'CONTENT_ITEMS.CONTENT' | translate }} ({{ items.length }})</span>
  </div>
  <div class="list-grid list-item ">
    <div class="content-item" *ngFor="let item of items">
      <a [routerLink]="getItemRoute(item) | localize" (click)="emitShouldCloseDrawer()">
        <span *ngIf="!item.thumbnail" class="thumbnail"></span>
        <img *ngIf="item.thumbnail" class="thumbnail" [src]="item.thumbnail" loading="lazy">
      </a>
    </div>
  </div>
</ng-template>



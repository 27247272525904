import { Location } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LocalizeParser, LocalizeRouterModule, LocalizeRouterSettings, ManualParserLoader } from '@gilsdav/ngx-translate-router';
import { TranslateService } from '@ngx-translate/core';

import { PageLayoutComponent } from './core/components/page-layout/page-layout.component';

const routes: Routes = [
  {
    path: '',
    component: PageLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./map/map.module').then(m => m.MapModule)
      },
      {
        path: 'ROUTES/:collection',
        pathMatch: 'full',
        loadChildren: () => import('./map/map.module').then(m => m.MapModule)
      },
      {
        path: 'TOPICS/:collection',
        pathMatch: 'full',
        loadChildren: () => import('./map/map.module').then(m => m.MapModule)
      },
      {
        path: 'ROUTES/:collection/POI/:poi',
        pathMatch: 'full',
        loadChildren: () => import('./map/map.module').then(m => m.MapModule)
      },
      {
        path: 'TOPICS/:collection/POI/:poi',
        pathMatch: 'full',
        loadChildren: () => import('./map/map.module').then(m => m.MapModule)
      },
      {
        path: 'POI/:poi',
        pathMatch: 'full',
        loadChildren: () => import('./map/map.module').then(m => m.MapModule)
      },
      {
        path: 'ROUTES/:collection/POI/:poi/CONTENT_ITEM/:id',
        pathMatch: 'full',
        loadChildren: () => import('./item/item.module').then(m => m.ItemModule)
      },
      {
        path: 'TOPICS/:collection/POI/:poi/CONTENT_ITEM/:id',
        pathMatch: 'full',
        loadChildren: () => import('./item/item.module').then(m => m.ItemModule)
      },
      {
        path: 'POI/:poi/CONTENT_ITEM/:id',
        pathMatch: 'full',
        loadChildren: () => import('./item/item.module').then(m => m.ItemModule)
      },
      {
        path: 'CONTENT_ITEM/:id',
        pathMatch: 'full',
        loadChildren: () => import('./item/item.module').then(m => m.ItemModule)
      },
      {
        path: 'TOPICS',
        loadChildren: () => import('./topics/topics.module').then(m => m.TopicsModule)
      },
      {
        path: 'ROUTES',
        loadChildren: () => import('./routes/routes.module').then(m => m.RoutesModule)
      },
      {
        path: 'LEGAL_NOTICE',
        data: { key: 'LEGAL_NOTICE' },
        loadChildren: () => import('./core/pages/static-page/static-page.module').then(m => m.StaticPageModule)
      },
      {
        path: '404',
        data: { key: '404' },
        loadChildren: () => import('./core/pages/static-page/static-page.module').then(m => m.StaticPageModule)
      },
      {
        path: '**',
        redirectTo: '/404',
        pathMatch: 'full'
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { initialNavigation: 'disabled' }),
    LocalizeRouterModule.forRoot(routes, {
      parser: {
        provide: LocalizeParser,
        useFactory: (translate: any, location: any, settings: any) =>
          new ManualParserLoader(translate, location, settings, ['de', 'en'], 'ROUTES.'),
        deps: [TranslateService, Location, LocalizeRouterSettings]
      },
      initialNavigation: true
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ContentItemDetailsDto } from '../dtos/content-item/content-item-details.dto';
import { ContentItemDto } from '../dtos/content-item/content-item.dto';
import { PaginationDto } from '../dtos/pagination.dto';
import { PoiDisplayType } from '../enums/poi-display-type.enum';
import { SortType } from '../enums/sort-type.enum';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class ContentItemService extends ApiService<ContentItemDto, ContentItemDetailsDto> {
  constructor(protected override http: HttpClient, protected override translateService: TranslateService) {
    super(http, ContentItemDetailsDto, 'content-items', translateService);
  }

  getPaginatedByType(
    type: PoiDisplayType,
    pageIndex?: number,
    pageSize?: number,
    sortType?: SortType,
    localizeSort: boolean = false,
    params?: object
  ): Promise<PaginationDto<ContentItemDto>> {
    if (type === PoiDisplayType.Info) {
      throw new Error('Invalid PoiDisplayType');
    }

    let modifiedParams: { [key: string]: any } = {};

    if (params) {
      modifiedParams = params;
    }

    modifiedParams['type'] = type.key;

    return this.getPaginated(pageIndex, pageSize, sortType, localizeSort, modifiedParams);
  }
}

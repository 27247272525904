import { IsNumber } from "class-validator";

export class CountersDto {

  @IsNumber()
  pois: number;

  @IsNumber()
  contentItems: number;

  @IsNumber()
  dossiers: number;

  @IsNumber()
  routes: number;

  @IsNumber()
  documents: number;

  @IsNumber()
  maps: number;

  @IsNumber()
  images: number;

}

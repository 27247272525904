import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalizedStringDto } from '../dtos/localized-string.dto';

@Pipe({name: 'localizeString', pure: false })
export class LocalizeStringPipe implements PipeTransform {
    constructor(private translateService: TranslateService) {}
  transform(value: LocalizedStringDto, useFallback: boolean = true): string | null {
    let translatedValue = value[this.translateService.currentLang as keyof LocalizedStringDto];
    if (useFallback && !translatedValue) {
      translatedValue = value['de' as keyof LocalizedStringDto]
    }
    return translatedValue;
  }
}
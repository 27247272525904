export class PaginationDto<T> {
  items: T[];

  pageNr: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;

  // TODO: Update API to be in accordance with usual PaginationDto?
  // totalItems: number;

  // pageSize: number;

  // pageIndex: number;

  // pageCount: number;

  // next?: number;

  // previous?: number;
}

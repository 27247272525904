import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PoiOverviewDto } from '../../dtos/poi/poi-overview.dto';
import { PoiDto } from '../../dtos/poi/poi.dto';

@Component({
  selector: 'app-poi-list-item',
  templateUrl: './poi-list-item.component.html',
  styleUrls: ['./poi-list-item.component.scss']
})
export class PoiListItemComponent {

  @Input()
  poi : PoiOverviewDto;

  @Output('selected')
  clickEmitter: EventEmitter<PoiOverviewDto> = new EventEmitter();

  constructor() {}

  selected(e: MouseEvent) {
    // This is to stop routerLinks on the host element to execute
    e.preventDefault();
    e.stopPropagation();

    this.clickEmitter.emit(this.poi);
  }
}

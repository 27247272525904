import { IsNotEmpty, IsString } from 'class-validator';

export class ReferenceDto {

  @IsNotEmpty() @IsString()
  id: string;

  @IsNotEmpty() @IsString()
  type: string;

}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card'
import { RouterModule } from '@angular/router';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { TranslateModule } from '@ngx-translate/core';
import { ImageCardComponent } from './components/image-card/image-card.component';
import { PageLayoutComponent } from './components/page-layout/page-layout.component';
import { PoiDetailComponent } from './components/poi-detail/poi-detail.component';
import { PoiListItemComponent } from './components/poi-list-item/poi-list-item.component';
import { LocalizeStringPipe } from './pipes/localize-string.pipe';
import { InitFocusComponent } from './components/init-focus/init-focus.component';
import { LoadingAnimationComponent } from './components/loading-animation/loading-animation.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
  declarations: [PageLayoutComponent, PoiListItemComponent, PoiDetailComponent, ImageCardComponent, LocalizeStringPipe, InitFocusComponent, LoadingAnimationComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatSidenavModule,
    MatListModule,
    MatTableModule,
    MatSortModule,
    MatMenuModule,
    MatDialogModule,
    MatInputModule,
    MatSelectModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatCardModule,
    TranslateModule,
    LocalizeRouterModule,
    InfiniteScrollModule
  ],
  exports: [MatTableModule, TranslateModule, LocalizeRouterModule, ImageCardComponent, LocalizeStringPipe]
})
export class CoreModule {}

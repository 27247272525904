import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { plainToClass, plainToClassFromExist } from 'class-transformer';
import { firstValueFrom } from 'rxjs';

import { environment } from '../../../environments/environment';
import { BaseDto } from '../dtos/base.dto';
import { PaginationDto } from '../dtos/pagination.dto';
import { SortType } from '../enums/sort-type.enum';

// T - Regular DTO; D - Details DTO
export class ApiService<T extends BaseDto, D extends BaseDto> {
  constructor(
    protected http: HttpClient,
    private detailDto: new (dto?: any) => D,
    protected readonly endpoint: string,
    protected translateService: TranslateService
  ) {}

  async getPaginated(
    pageIndex = 0,
    pageSize = 10,
    sortType?: SortType,
    localizeSort: boolean = false,
    params?: object
  ): Promise<PaginationDto<T>> {
    let sorting = {};
    if (sortType) {
      let sortField = sortType.toString();
      if (localizeSort) {
        sortField += '.' + this.translateService.currentLang;
      }
      sorting = {
        sort: `${sortField},${sortType === (SortType.Updated || SortType.Year) ? 'desc' : 'asc'}`
      };
    }

    const request = this.http.get(`${environment.apiUrl}/${this.endpoint}`, {
      params: {
        ...params,
        ...sorting,
        pageNr: String(pageIndex),
        pageSize: String(pageSize),
        ...(environment.apiKey ? { apikey: environment.apiKey } : {})
      }
    });

    const dto = (await firstValueFrom(request)) as any;
    const o = new PaginationDto<T>();
    return plainToClassFromExist(o, dto);
  }

  async get(id: string): Promise<D> {
    const request = this.http.get(
      `${environment.apiUrl}/${this.endpoint}/${id}`,
      environment.apiKey ? { params: { apikey: environment.apiKey } } : undefined
    );
    const dto = await firstValueFrom(request);
    return plainToClass(this.detailDto, dto);
  }
}

import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged, firstValueFrom, Subject, Subscription } from 'rxjs';
import { DossierDto } from '../../dtos/dossier/dossier.dto';
import { PoiDetailsDto } from '../../dtos/poi/poi-details.dto';
import { RouteDto } from '../../dtos/route/route.dto';
import { SearchDto } from '../../dtos/search.dto';
import { CollectionType } from '../../enums/collection-type.enum';
import { PoiDisplayType } from '../../enums/poi-display-type.enum';
import { PoiDisplayService } from '../../services/poi-display.service';
import { SearchService } from '../../services/search.service';
import { Location } from '@angular/common';
import { MatDrawer } from '@angular/material/sidenav';
import { CountersDto } from '../../dtos/counters.dto';
import { PoiOverviewDto } from '../../dtos/poi/poi-overview.dto';

@Component({
  selector: 'app-page-layout',
  templateUrl: './page-layout.component.html',
  styleUrls: ['./page-layout.component.scss']
})
export class PageLayoutComponent implements OnInit, OnDestroy {
  CollectionType = CollectionType;

  pois?: PoiOverviewDto[];
  collectionPois?: PoiOverviewDto[];
  selectedPoi?: PoiDetailsDto;
  search?: { [key: string]: SearchDto[] };

  showLoading: boolean = false;
  searchUpdating: boolean = false;

  initialLoadInProgress: boolean = true;

  @ViewChild('search') searchInput: ElementRef<HTMLInputElement>;
  @ViewChild('nav') matDrawer: MatDrawer;

  readonly searchTypes : { key: string, label: string; showImage: boolean }[]  = [
    { key: PoiDisplayType.Info.key, label: 'POIS', showImage: false },
    { key: PoiDisplayType.Image.key, label: 'IMAGES', showImage: true },
    { key: PoiDisplayType.Document.key, label: 'DOCUMENTS', showImage: false },
    { key: PoiDisplayType.Map.key, label: 'MAPS', showImage: true }
  ]

  private counters: CountersDto;

  private poiSubscription: Subscription;
  private selectedPoiSubscription: Subscription;
  private searchSubscription: Subscription;
  private collectionSubscription: Subscription;
  private matDrawerSubscription: Subscription;
  private countersSubscription: Subscription;
  private poisLoadedSubscription: Subscription;

  private searchResultSubscription: Subscription;

  private searchTimeout = new Subject<string | undefined>();

  private readonly stepFilteredPois = 30;

  private allPois?: PoiOverviewDto[];
  private allCollectionPois?: PoiOverviewDto[];
  private currentPoiPage = 0;
  private currentCollectionPoiPage = 0;

  constructor(
    private media: MediaMatcher,
    private cd: ChangeDetectorRef,
    private translateService: TranslateService,
    private localizeRouterService: LocalizeRouterService,
    private poiDisplayService: PoiDisplayService,
    private searchService: SearchService,
    private location: Location
  ) {}

  ngOnInit() {
    this.poiDisplayService.mobileQuery = this.media.matchMedia('(max-width: 992px)');
    this.poiDisplayService.mobileQueryListener = () => { this.cd.detectChanges() };
    this.poiDisplayService.mobileQuery.addEventListener('change', this.poiDisplayService.mobileQueryListener);

    this.poiSubscription = this.poiDisplayService.filteredPois.subscribe(async(pois) => {
        if (await firstValueFrom(this.poiDisplayService.recenteringInProgress)) {
          if (!this.selectedCollection) {
            return;
          }
        }
        this.allPois = pois;
        this.currentPoiPage = 0;
        this.addPoiPage();
    });

    this.selectedPoiSubscription = this.poiDisplayService.selectedPoi.subscribe(poi => {
      this.selectedPoi = poi ?? undefined
      if (this.selectedPoi && this.matDrawer) {
        this.matDrawer.open();
      } else {
        this.patchSearchField(this.searchService.searchFilter);
      }
    });

    this.collectionSubscription = this.poiDisplayService.collectionPois.subscribe(pois => {
      this.allCollectionPois = pois ?? undefined;
      this.currentCollectionPoiPage = 0;
      this.addCollectionPoiPage();
      if (this.allCollectionPois) {
        this.searchTimeout.next(undefined);
        if (this.matDrawer) {
          this.matDrawer.open();
        }
      }
    });

    this.searchSubscription = this.searchTimeout.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe(value => this.executeSearch(value));

    this.searchResultSubscription = this.searchService.search.subscribe(searchResult => {
      this.search = searchResult;
      if (this.collectionPois) {
        this.poiDisplayService.clearCollection();
      }
      if (this.matDrawer) {
        this.matDrawer.open();
      }
    });

    this.matDrawerSubscription = this.poiDisplayService.shouldCloseDrawer.subscribe(shouldClose => {
      if (shouldClose && this.mobile) {
        this.matDrawer.close();
        this.poiDisplayService.shouldCloseDrawer.next(false);
      }
    });

    this.countersSubscription = this.poiDisplayService.counters.subscribe(counters => {
      if (counters) {
        this.counters = counters;
      }
    });

    this.poisLoadedSubscription = this.poiDisplayService.loaded.subscribe(loaded => {
      this.initialLoadInProgress = !loaded;
    });
  }

  ngAfterViewInit() {
    if (this.searchService.searchFilter) {
      this.searchInput.nativeElement.value = this.searchService.searchFilter;
    }
  }

  ngOnDestroy() {
    this.poiDisplayService.mobileQuery.removeEventListener('change', this.poiDisplayService.mobileQueryListener);

    if (this.poiSubscription) {
      this.poiSubscription.unsubscribe();
    }

    if (this.selectedPoiSubscription) {
      this.selectedPoiSubscription.unsubscribe();
    }

    if (this.collectionSubscription) {
      this.collectionSubscription.unsubscribe();
    }

    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    
    if (this.searchResultSubscription) {
      this.searchResultSubscription.unsubscribe();
    }

    if (this.matDrawerSubscription) {
      this.matDrawerSubscription.unsubscribe();
    }

    if (this.countersSubscription) {
      this.countersSubscription.unsubscribe();
    }
    
    if (this.poisLoadedSubscription) {
      this.poisLoadedSubscription.unsubscribe();
    }
  }

  onScroll() {
    this.allCollectionPois ? this.addCollectionPoiPage() : this.addPoiPage();
  }

  switchLanguage() {
    this.pois = undefined;

    if (this.currentLang === 'de') {
      this.localizeRouterService.changeLanguage('en');
    } else {
      this.localizeRouterService.changeLanguage('de');
    }

    if (this.mobile) {
      this.matDrawer.close();
    }
  }

  closeDrawerForMobile() {
    if (this.mobile) {
      this.matDrawer.close();
    }
  }

  async clearAll(recenterMap: boolean) {
    this.poiDisplayService.recenteringInProgress.next(recenterMap);
    this.clearCollection();
    this.clearPois();
    this.unselectPoi();
    
    this.poiDisplayService.shouldRecenterMap.next(recenterMap);

    if (this.mobile) {
      this.matDrawer.close();
    }
  }

  clearPois() {
    this.pois = undefined;

    if (this.searchValue) {
      this.clearSearch();
    }
  }

  clearCollection() {
    this.collectionPois = undefined;
    this.poiDisplayService.clearCollection();
  }

  unselectPoi() {
    const translatedUrl = this.localizeRouterService.translateRoute(this.routerLinkCollectionOrMap) as string;
    // Push empty state to replace
    history.pushState('', '');
    this.location.replaceState(translatedUrl);
    this.poiDisplayService.clearPoi();
    if (this.collectionType) {
     this.poiDisplayService.shouldRecenterMap.next(true); 
    }    
    if (!this.collectionType && this.mobile) {
      this.matDrawer.close();
    }
  }

  addPoiPage() {
    if (!this.allPois) {
      this.pois === undefined;
      return;
    }
    if (!this.pois || this.currentPoiPage === 0) {
      this.pois = [];
    }
    const sliced = this.allPois.slice(this.currentPoiPage * this.stepFilteredPois, ++this.currentPoiPage * this.stepFilteredPois);
    this.pois.push(...sliced); 
  }

  addCollectionPoiPage() {
    if (!this.allCollectionPois) {
      this.collectionPois === undefined;
      return;
    }
    if (!this.collectionPois || this.currentCollectionPoiPage === 0) {
      this.collectionPois = [];
    }
    const sliced = this.allCollectionPois.slice(this.currentCollectionPoiPage * this.stepFilteredPois, ++this.currentCollectionPoiPage * this.stepFilteredPois);
    this.collectionPois.push(...sliced); 
  }

  updateSearch(e: any) {
    this.searchUpdating = true;
    this.searchTimeout.next(this.searchValue);
  }

  clearSearch() {
    this.searchInput.nativeElement.value = '';
    this.searchTimeout.next(undefined);
  }

  executeSearch(value?: string) {
    this.searchService.setSearchFilter(value);
    this.searchUpdating = false;
  }

  patchSearchField(overrideFilter?: string) {
    if (this.searchService.searchFilter || overrideFilter) {
      if (this.searchInput?.nativeElement) {
        this.searchInput.nativeElement.value = overrideFilter || this.searchService.searchFilter || '';
      } else {
        setTimeout((_: any) => this.patchSearchField(overrideFilter), 15);
      }
    }
  }

  showLessLink(typeKey: string) : boolean {
    return (this.search && this.search[typeKey] && this.search[typeKey].length > this.searchService.searchResultsPerRequest) ?? false;
  }

  showMoreLink(typeKey: string) : boolean {
    return !this.searchService.reachedMax[typeKey];
  }

  async removeResults(typeKey: string) {
    this.searchService.removeResults(typeKey);
  }

  async addResults(typeKey: string) {
    this.searchService.addResults(typeKey);
  }

  async selectPoi(poi: PoiOverviewDto) {
    let url = this.routerLinkCollectionOrMap;
    if (url.length !== 1) {
      url += '/'
    }
    url = `${url}POI/${poi.id}`;
    const translatedUrl = this.localizeRouterService.translateRoute(url) as string;
    // Push empty state to replace
    history.pushState('', '');
    this.location.replaceState(translatedUrl);
    this.poiDisplayService.selectPoi(poi);
    if (this.mobile) {
      this.matDrawer.open();
    }
  }

  async selectPoiFromSearch(poi: SearchDto) {
    const translatedUrl = this.localizeRouterService.translateRoute(`/POI/${poi.id}`) as string;
    // Push empty state to replace
    history.pushState('', '');
    this.location.replaceState(translatedUrl);
    this.poiDisplayService.selectPoiById(poi.id);
  }

  collectionIsRoute(selectedCollection: DossierDto | RouteDto | null, collectionType: CollectionType): selectedCollection is RouteDto {
    return collectionType === CollectionType.Route;
  }

  get countPois(): number | null {
    return this.counters?.pois;
  }

  get countMaps(): number | null {
    return this.counters?.maps;
  }

  get countImages(): number | null {
    return this.counters?.images;
  }

  get countDocuments(): number | null {
    return this.counters?.documents;
  }

  get mobile(): boolean {
    return this.poiDisplayService.mobile;
  }

  get currentLang(): string {
    return this.translateService.currentLang;
  }

  get otherLang(): string {
    return this.currentLang === 'de' ? 'en' : 'de';
  }

  get searchValue(): string | undefined {
    let value = this.searchInput?.nativeElement?.value;
    return value && value.length > 0 ? value : undefined;
  }

  get searchLoading(): boolean {
    let values = Object.values(this.searchService.searchLoading);
    return values.length > 0 && values.reduce((prev, current) => prev && current);
  }

  get hasSearchResult(): boolean {
    // Using only 'this.search &&' throws an error
    return this.search !== undefined && Object.values(this.search).filter(searchResult => searchResult.length > 0).length > 0;
  }

  get selectedCollection(): DossierDto | RouteDto | null {
    return this.poiDisplayService.selectedCollection;
  }

  get collectionType(): CollectionType {
    return this.poiDisplayService.collectionType;
  }

  get routerLinkCollectionOrMap(): string {
    if (!this.selectedCollection || this.collectionType === CollectionType.None) {
      return '/';
    }
    return `${this.routerLinkRoutesOrTopics}/${this.selectedCollection.id}`;
  }

  get routerLinkRoutesOrTopics() {
    if (!this.selectedCollection || this.collectionType === CollectionType.None) {
      return '/';
    }
    return `/${this.collectionType === CollectionType.Route ? 'ROUTES' : 'TOPICS'}`
  }

  get routerLinkPoi(): string {
    let link = '/';
    if (this.selectedCollection && this.collectionType !== CollectionType.None) {
      link += this.collectionType === CollectionType.Route ? 'ROUTES' : 'TOPICS' + '/';
    }
    link += 'POI/';
    return link;
  }

  private get collectionRoute(): string {
    if (this.selectedCollection) {
      switch (this.collectionType) {
        case CollectionType.Route:
          return "/ROUTES/" + this.selectedCollection.id;
        case CollectionType.Dossier:
          return "/TOPICS/" + this.selectedCollection.id;
        default:
          // pass
          break;
      }
    }
    return '';
  }

  get currentCollectionShareLink(): string {
    return location.origin + this.localizeRouterService.translateRoute(this.collectionRoute) as string;
  }

  get serviceLoading(): boolean {
    return this.poiDisplayService.updatingAfterBroswerNavigation;
  }

  get allPoisDisplayed(): boolean {
    return (this.pois?.length ?? 0) === (this.allPois?.length ?? 0)
  }

  get allCollectionPoisDisplayed(): boolean {
    return (this.collectionPois?.length ?? 0) === (this.allCollectionPois?.length ?? 0)
  }
}

import { ContentItemUpdateDto } from './content-item-update.dto';
import { IsNotEmpty } from 'class-validator';


export class ContentItemDto extends ContentItemUpdateDto {

  @IsNotEmpty()
  id: string;

}

<app-loading-animation *ngIf="initialLoadInProgress"></app-loading-animation>
<div class="page-container" [class.mobile]="mobile">
  <mat-toolbar color="white" class="toolbar">
    <mat-toolbar-row>
      <div class="navigation">
        <a class="eth-logo" (click)="clearAll(true)" [routerLink]="'/' | localize">
          <img src="assets/ethz-logo.svg" alt="ETH Zürich Logo" />
        </a>
        <a class="logo" (click)="clearAll(true)" [routerLink]="'/' | localize">ETHorama</a>
      </div>

      <div class="actions">
        <button *ngIf="!mobile" mat-flat-button color="accent" (click)="switchLanguage()">
          {{ 'LANGUAGE.' + otherLang | translate }}
        </button>

        <button *ngIf="mobile" class="lang-button-mobile" mat-flat-button color="accent" (click)="switchLanguage()">
          <mat-icon class="language-icon">language</mat-icon>
          {{ otherLang.toUpperCase() | translate }}
        </button>

        <button class="menu" mat-icon-button (click)="nav.toggle()">
          <mat-icon>menu</mat-icon>
        </button>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>

  <mat-sidenav-container class="sidenav-container" [hasBackdrop]="false">
    <mat-sidenav
      #nav
      class="sidenav"
      mode="over"
      [opened]="!mobile"
      [fixedInViewport]="mobile"
      fixedTopGap="56"
      disableClose
      position="end"
    > 
      <app-init-focus></app-init-focus>
      <mat-nav-list>
        <span *ngIf="!selectedPoi" mat-list-item class="search">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'ACTIONS.SEARCH' | translate }}</mat-label>
            <mat-icon matPrefix class="search-icon" aria-hidden="true">search</mat-icon>
            <input #search matInput type="text" (keyup)="updateSearch(this)" />
            <mat-icon
              matSuffix
              class="clear-search-icon"
              (click)="clearSearch()"
              *ngIf="searchValue"
              attr.aria-label="{{ 'SEARCH.CLEAR' | translate }}"
            >
              close
            </mat-icon>
          </mat-form-field>
        </span>

        <ng-container
          *ngTemplateOutlet="
            serviceLoading ? ((selectedPoi || selectedCollection || pois || collectionPois) ? dataLoading : subNavigation) : 
            selectedPoi ? poiDetail : 
              searchValue ? searchResult : 
                (pois && pois.length > 0) || (collectionPois && collectionPois.length > 0) ? poiOverview : subNavigation
          "
        ></ng-container>

        <ng-template #dataLoading>
          <mat-list-item class="loading-list-item">
            <img src="/assets/images/icons/loading.gif" alt="{{ 'ACTIONS.LOADING' | translate }}" />
          </mat-list-item>
        </ng-template>

        <ng-template #poiDetail>
          <a class="header-list-item" mat-list-item (click)="unselectPoi()" [routerLink]="routerLinkCollectionOrMap | localize">
            <mat-icon>arrow_back</mat-icon>
            <div (click)="$event.preventDefault();">
              {{ 'ACTIONS.' + (selectedCollection && collectionType !== CollectionType.None ? (collectionType === CollectionType.Route ? 'BACK_TO_ROUTE' : 'BACK_TO_DOSSIER') : 'BACK_TO_MAP_SECTION') | translate }}
            </div>
          </a>
          <app-poi-detail *ngIf="selectedPoi" [poi]="selectedPoi"></app-poi-detail>
        </ng-template>

        <ng-template #poiOverview>
          <a infinite-scroll
          [infiniteScrollContainer]="'.mat-drawer-inner-container'"
          [fromRoot]="true"
          (scrolled)="onScroll()"
          [scrollWindow]="false" class="header-list-item" mat-list-item (click)="selectedCollection ? clearCollection() : clearPois()" [routerLink]="'/' | localize">
            <mat-icon>arrow_back</mat-icon>
            <div (click)="$event.preventDefault();">
              {{ 'ACTIONS.' + ((selectedCollection && pois) ? 'BACK_TO_MAP_OVERVIEW' : 'BACK_TO_HOME') | translate }}
            </div>
          </a>
          <div class="collection-info" *ngIf="selectedCollection">
            <div class="list-item subtitle">
              <a *ngIf="collectionType !== CollectionType.None" (click)="closeDrawerForMobile()" class="breadcrumb" [routerLink]="routerLinkRoutesOrTopics | localize">
                {{ (collectionType === CollectionType.Dossier ? 'NAVIGATION.THEMATIC_COLLECTIONS' : 'NAVIGATION.HISTORICAL_TRAVELS') | translate }}
              </a>
              <div class="share-links">
                <a [href]="'http://www.facebook.com/share.php?u=' + currentCollectionShareLink" class="share-facebook" target="_blank" rel="noopener"></a>
                <a [href]="'http://twitter.com/share?url=' + currentCollectionShareLink" class="share-twitter" target="_blank" rel="noopener"></a>
              </div>
            </div>
            <h2 class="title">{{ collectionIsRoute(selectedCollection, collectionType) ? selectedCollection.year + ' - ' : '' }}{{ selectedCollection.title | localizeString }}</h2>
            <p class="description">{{ selectedCollection.description | localizeString }}</p>
            <p class="description" *ngIf="collectionIsRoute(selectedCollection, collectionType) && selectedCollection.description2">{{ selectedCollection.description2 | localizeString }}</p>
          </div>
          <app-poi-list-item *ngFor="let poi of (collectionPois ?? pois)" [poi]="poi" (selected)="selectPoi(poi)" loading="lazy" [routerLink]="routerLinkPoi + poi.id | localize"></app-poi-list-item>
          <mat-list-item class="search-list-item" *ngIf="(collectionPois && !allCollectionPoisDisplayed) || (!collectionPois && !allPoisDisplayed)">
            <img src="/assets/images/icons/loading.gif" alt="{{ 'SEARCH.LOADING' | translate }}" />
          </mat-list-item>
        </ng-template>

        <ng-template #searchResult>
          <mat-list-item class="search-list-item" *ngIf="!searchUpdating && !searchLoading && !hasSearchResult">
            {{ 'SEARCH.NO_RESULT' | translate }}
          </mat-list-item>
          <div *ngIf="search && !searchLoading">
            <div class="search-result-wrapper" *ngFor="let searchType of searchTypes">
              <div class="search-result-block" *ngIf="search[searchType.key] && search[searchType.key].length > 0">
                <h4 class="search-result-title">
                  {{ 'SEARCH.TYPES.' + searchType.label | translate }}
                </h4>
                <div [class]="searchType.showImage ? 'search-result-image-content' : 'search-result-text-content'">
                  <div class="search-result-item" *ngFor="let item of search[searchType.key]">
                    <div *ngIf="searchType.key === 'info'" (click)="selectPoiFromSearch(item)">
                      <ng-container *ngTemplateOutlet="searchResultItem"></ng-container>
                    </div>
                    <a (click)="closeDrawerForMobile()" [routerLink]="'/CONTENT_ITEM/' + item.id | localize" *ngIf="searchType.key !== 'info'">
                      <ng-container *ngTemplateOutlet="searchResultItem"></ng-container>
                    </a>
                    <ng-template #searchResultItem>
                      <img [src]="item.thumbnail" *ngIf="searchType.showImage" loading="lazy"/>
                      <span class="text-result" *ngIf="!searchType.showImage">{{ item.getTitle(currentLang) }}</span>
                    </ng-template>
                  </div>
                  <div *ngIf="showLessLink(searchType.key)" class="search-result-less" (click)="removeResults(searchType.key)">
                    <span>{{ 'SEARCH.SHOW_LESS' | translate }}</span>
                  </div>
                  <div *ngIf="showMoreLink(searchType.key)"  class="search-result-more" (click)="addResults(searchType.key)">
                    <span>{{ 'SEARCH.SHOW_MORE' | translate }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <mat-list-item class="search-list-item" *ngIf="searchUpdating || searchLoading">
            <img src="/assets/images/icons/loading.gif" alt="{{ 'SEARCH.LOADING' | translate }}" />
          </mat-list-item>
        </ng-template>

        <ng-template #subNavigation>
          <a
            [routerLink]="'/' | localize"
            class="list-item"
            mat-list-item
            routerLinkActive="active"
            (click)="clearAll(rla.isActive)"
            [routerLinkActiveOptions]="{ exact: true }"
            #rla="routerLinkActive"
          >
            <mat-icon>map</mat-icon>
            {{ 'NAVIGATION.MAP' | translate }}
          </a>
          <a
            [routerLink]="'/TOPICS' | localize"
            class="list-item"
            mat-list-item
            routerLinkActive="active"
            (click)="closeDrawerForMobile()"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <mat-icon>collections_bookmark</mat-icon>
            {{ 'NAVIGATION.THEMATIC_COLLECTIONS' | translate }}
          </a>
          <a
            [routerLink]="'/ROUTES' | localize"
            class="list-item"
            mat-list-item
            routerLinkActive="active"
            (click)="closeDrawerForMobile()"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <mat-icon>explore</mat-icon>
            {{ 'NAVIGATION.HISTORICAL_TRAVELS' | translate }}
          </a>
          
          <div class="about static-page-content" *ngIf="countPois" [innerHtml]="'STATIC_PAGES.ABOUT.CONTENT' | translate: { countImages, countDocuments, countMaps, countPois }"></div>

          <div class="footer">
            <a mat-list-item [routerLink]="'/LEGAL_NOTICE' | localize" routerLinkActive="active" (click)="closeDrawerForMobile()">
              {{ 'NAVIGATION.LEGAL_NOTICE' | translate }}
            </a>
            <a mat-list-item [href]="'https://library.ethz.ch/' + currentLang + '/'" target="_blank">
              {{ 'NAVIGATION.ETH_LIBRARY' | translate }}
              <img src="/assets/images/icons/link-external-dark.png" style="padding-left: 5px;" aria-disabled="true" />
            </a>
          </div>
        </ng-template>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content class="content">
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

import { LocalizedStringDto } from "./localized-string.dto";

export class SearchDto {
  constructor(id: string, thumbnail: string | null, title: LocalizedStringDto | string) {
    this.id = id;
    this.thumbnail = thumbnail;
    this.title = title;
  }

  id: string;
  thumbnail: string | null;

  private title: LocalizedStringDto | string;

  getTitle(lang: string) : string {
    return typeof this.title === 'string' ? this.title : (this.title[lang as keyof LocalizedStringDto] ?? this.title['de' as keyof LocalizedStringDto]!)
  }
}

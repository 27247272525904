import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ContentItemDto } from '../dtos/content-item/content-item.dto';

import { PaginationDto } from '../dtos/pagination.dto';
import { PoiDto } from '../dtos/poi/poi.dto';
import { SearchDto } from '../dtos/search.dto';
import { PoiDisplayType } from '../enums/poi-display-type.enum';
import { ContentItemService } from './content-item.service';
import { PoiService } from './poi.service';

@Injectable({
  providedIn: 'root'
})
// TODO  Add routes & dossiers
export class SearchService {
  search: BehaviorSubject<{ [key: string]: SearchDto[] }> = new BehaviorSubject<{ [key: string]: SearchDto[] }>({}); 

  reachedMax: { [key: string]: boolean } = {};
  currentPage: { [key: string]: number } = {};

  searchFilter?: string;
  // Set initial value, so the "no results" is not shown when first searching
  searchLoading: { [key: string]: boolean } = {
    'info': true
  };

  readonly searchResultsPerRequest = 20;

  private pagination: { [key: string]: PaginationDto<PoiDto> | PaginationDto<ContentItemDto> } = {};
  private searchItems: { [key: string]: SearchDto[] } = {};

  constructor(private poiService: PoiService, private contentItemService: ContentItemService) {}

  setSearchFilter(search?: string) {
    this.searchFilter = search;
    this.applyFilter();
  }

  loadNextSearchPage(type: PoiDisplayType) {
    this.loadSearchPage(type, this.currentPage[type.key] + 1);
  }

  async removeResults(typeKey: string) {
    this.currentPage[typeKey] = this.currentPage[typeKey] - 1;
    this.reachedMax[typeKey] = this.currentPage[typeKey] >= this.pagination[typeKey].totalPages - 1;
    this.searchItems[typeKey] = this.searchItems[typeKey].slice(0, (this.currentPage[typeKey] + 1) * this.searchResultsPerRequest);
    this.search.next(this.searchItems);
  }

  async addResults(typeKey: string) {
    const type = PoiDisplayType.getByKey(typeKey);
    if (type) {
      this.loadNextSearchPage(type);
    }
  }

  applyFilter() {
    this.searchItems = {};
    if (!this.searchFilter) {
      return;
    }
    this.loadSearchPage(PoiDisplayType.Info, 0);
    this.loadSearchPage(PoiDisplayType.Map, 0);
    this.loadSearchPage(PoiDisplayType.Image, 0);
    this.loadSearchPage(PoiDisplayType.Document, 0);
  }

  private async loadSearchPage(type: PoiDisplayType, pageNumber: number) {
    if (pageNumber !== 0) {
      if (this.currentPage[type.key] === pageNumber) {
        // Data already loaded
        return;
      }
  
      if (this.reachedMax[type.key]) {
        // Max Page already reached
        return;
      }
    } else {
      this.searchItems[type.key] = [];
    }

    this.searchLoading[type.key] = true;

    switch (type) {
      case PoiDisplayType.Info:
        this.pagination[type.key] = await this.poiService.getPaginated(pageNumber, this.searchResultsPerRequest, undefined, undefined, {
          search: this.searchFilter
        });
        break;
      default: // All ContentItem Types
        this.pagination[type.key] = await this.contentItemService.getPaginatedByType(type, pageNumber, this.searchResultsPerRequest, undefined, undefined, {
          search: this.searchFilter
        });
        break;
    }
    
    this.searchItems[type.key] = (this.searchItems[type.key] as any).concat(this.map(type, this.pagination[type.key].items));
    this.search.next(this.searchItems);
    
    this.searchLoading[type.key] = false;
    this.currentPage[type.key] = pageNumber;
    this.reachedMax[type.key] = this.currentPage[type.key] >= this.pagination[type.key].totalPages - 1;
  }

  private map(type: PoiDisplayType, items: PoiDto[] | ContentItemDto[]) : SearchDto[] {
    switch(type) {
      case PoiDisplayType.Info:
        return items.map((item) => {
          let poi = item as PoiDto;
          let searchDto = new SearchDto(poi.id, poi.thumbnail, poi.name);
          return searchDto;
        });
      default: // Content Items
        return items.map((item) => {
          let contentItem = item as ContentItemDto;
          let searchDto = new SearchDto(contentItem.id, contentItem.thumbnail, contentItem.title);
          return searchDto;
        });
    }
  }
}

import loadGoogleMapsApi from 'load-google-maps-api';

export function loadGoogleMaps(googleMapsKey: string) {
  return () =>
    new Promise<void>(resolve => {
      if (!window) {
        console.error('no window');
        resolve();
      }

      loadGoogleMapsApi({ key: googleMapsKey })
        .then(_ => resolve())
        .catch(e => {
          console.error(e);
          resolve();
        });
    });
}
